import http from '../utils/http'
import * as URL from './urls'


/*
 * @Descripttion: invite模块需要用到的相关接口请求
 * @version: 
 * @Author: hongtao
 * @Date:
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-07 19:51:33
 */

/**
 * @description: 获取邀请相关信息的请求
 * @param {*}
 * @return {*}
 */
export const getInviteInfo = () => {
  return http.request("GET", URL.INVITE_INFO_URL)
}

/**
 * @description: 获取被邀请人列表的请求
 * @param {*}
 * @return {*}
 */
 export const getInvitePage = (page, size) => {
  return http.request("GET", URL.INVITE_PAGE_URL, {
    page, size
  })
}

/**
 * @description: 邀请助力人数达标，领取兑换次数
 * @param {*}
 * @return {*}
 */
export const acquireOpportunity = () => {
  return http.request("POST", URL.ACQUIRE_OPPORTUNITY_URL)
}

/**
 * @description: 奖品二选一
 * @return {*}
 */
export const saveOneAward = async (data) => await http.request('POST', URL.SAVE_ONE_AWARD, data);

/**
 * @description: 依据scene获取活动相关信息
 * @param {*} data
 * @return {*}
 */
export const getInfoByScene = async (data) => await http.request('GET',URL.GET_MESSAGE_SCENE, data);

/**
 * @description: 获取活动规则描述
 * @param {*} data
 * @return {*}
 */
export const getRuleByType = async (data) => await http.request('GET',URL.GET_RULE_TYPE, data);

// 前置校验接口
export const preCheck = async () => await http.request('GET',URL.PRE_CHECK);

