import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './invitePage.scss';
import InviteeInfoCard from '../../components/InviteeInfoCard/InviteeInfoCard'
import PosterModal from '../../components/PosterModal/PosterModal'
import { useRecoilState } from "recoil";
import { singleAwardState, alreadyAwardState } from '../../state/atom';
import { getInviteInfo, getInvitePage } from '../../api/invite'
import { generatePoster } from '../../api/poster'
import { showExchange } from '../../api/exchange'
import { throttle } from '../../utils/functionUtils'
import { PullToRefresh, InfiniteScroll } from 'antd-mobile'
import { sleep } from 'antd-mobile/es/utils/sleep'
import { centerToast } from "../../components/Toast/Toast";

/*
* @Description: 邀请好友页
* @version:
* @Author: hongtao
* @Date:
* @LastEditors: hongtao
* @LastEditTime:
*/
export default function InvitePage() {
  const navigate = useNavigate();
  const [singleAward, setSingleAward] = useRecoilState(singleAwardState);
  const [alreadyAwardInfo, setAlreadyAwardInfo] = useRecoilState(alreadyAwardState);
  const [posterUrl, setPosterUrl] = useState('')
  const [posterModalVisible, setPosterModalVisible] = useState(false);
  const [inviteInfo, setInviteInfo] = useState({});
  const [inviteeList, setInviteeList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [hasMore, setHasMore] = useState(true)
  const [totalCount, setTotalCount] = useState(NaN)

  const showPoster = () => {
    if (showLoading) {
      return false
    }
    if (posterUrl) {
      setPosterModalVisible(true)
    } else {
      setShowLoading(true)
      setTimeout(()=>{
        if (showLoading) {
          centerToast('生成海报失败，请稍后重试')
          setShowLoading(false)
        }
      },6000)
    }
  }

  const showInviteModal = async () => {
    const res = await showExchange()
    if (res&&res.data) {
      setSingleAward(res.data);
      navigate('/activity-nft/result')
    }
  }

  const fetchInvitePageInitInfo = async () => {
    const [inviteInfoRes, invitePageRes] = await Promise.all([
      getInviteInfo(),
      getInvitePage(1, 20),
    ])
    if (inviteInfoRes) {
      setInviteInfo(inviteInfoRes.data)
    }
    if (invitePageRes) {
      setInviteeList(invitePageRes.data.inviteeList)
      setTotalCount(invitePageRes.data.totalCount)
    }
  }

  const fetchInvitePoster = async () => {
    let codeScene = 'H5'
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('miniprogram') > -1) {
      codeScene = 'MINI_PROGRAM'
    }
    const posterInfo = await generatePoster(codeScene)
    if (posterInfo) {
      setPosterUrl(posterInfo?.data)
    }
  }

  const loadMoreInviteeList = async () => {
    if (totalCount<=inviteeList.length) {
      setHasMore(false)
      return false
    }
    const page = Math.ceil(inviteeList.length/20) + 1
    if (page < 2) {
      return false;
    }
    const res = await getInvitePage(page, 20)
    const append = res?.data?.inviteeList
    setInviteeList(val => [...val, ...append])
    setHasMore(append.length > 0)
  }

  useEffect(() => {
    const token = localStorage.getItem('Authorization')
    if (!token) {
      const {origin} = window.location;
      const url = `${origin}/activity-nft/`;
      window.location.replace(url);
    }
    if (alreadyAwardInfo) {
      const token = localStorage.getItem('Authorization');
      const {origin}  = window.location;
      const url = `${origin}/activity-nft/?token=${token}&realLogin=true`;
      window.location.replace(url);
    }
    window.scrollTo(0, 0);
    setHasMore(true)
    fetchInvitePageInitInfo()
    fetchInvitePoster()
  },[refreshTrigger])

  useEffect(() => {
    if(showLoading===true) {
      setShowLoading(false)
      setPosterModalVisible(true)
    }
  },[posterUrl])

  return (
    <PullToRefresh
      onRefresh={async () => {
        await sleep(800)
        setRefreshTrigger(!refreshTrigger)
      }}
    >
      <div className="invite-page">
        <PosterModal visible={posterModalVisible} posterUrl={posterUrl} setVisible={setPosterModalVisible}/>
        <div className="jidu-icon"></div>
        <div className="lunar-subtitle">LUNAR</div>
        <div className="page-main-title-first">探月寻兔</div>
        <div className="page-main-title-second">开舱门 赢权益 看发射</div>
        <div className="nft-poster-back-container">
          {
            inviteInfo&&inviteInfo.posterUrl?
            <img className="nft-image" src={inviteInfo?.posterUrl} alt="" />:
            <div className="nft-loading-dots"></div>
          }
          <div className="digital-collection-text"></div>
        </div>
        <div className="tips-text">{inviteInfo.awardInfo}</div>
        {inviteInfo.code===0?
          <div className="collect-invite-rewards-button" onClick={throttle(showInviteModal)}>领取邀请奖励</div>
          :null
        }
        {showLoading?
          <div className="save-poster-button loading-status">
            <div>海报加载中</div>
            <div className="loading-dots"></div>
          </div>:
          <div className={`save-poster-button ${inviteInfo.code===1?'save-poster-button-purple':''}`} onClick={showPoster}>生成邀请海报</div>
        }
        <div className="invite-subtitle">INVITE</div>
        <div className="invite-record-title">邀请记录</div>
        <div className="invite-record-subtitle">{inviteInfo&&inviteInfo.highlightContents&&inviteInfo.highlightContents.length>0?inviteInfo.highlightContents[0].contentText:''}</div>
        <div className="invite-record-info-container">
          {inviteeList&&inviteeList.length>0?inviteeList.map((inviteeInfo,index)=>{
            return (
              <InviteeInfoCard inviteeInfo={inviteeInfo} key={index}/>
            )
          }):null}
          {
            !inviteeList||inviteeList.length===0?
            <div className="empty-sign-container">
              <div className="empty-sign"></div>
              <div className="no-record-text">暂无邀请</div>
            </div>
            :null
          }
        </div>
        <div className="bottom-light"></div>
        <div className="bottom-block"></div>
      </div>
      <InfiniteScroll loadMore={loadMoreInviteeList} hasMore={hasMore} threshold={0}>
        <></>
      </InfiniteScroll>
    </PullToRefresh>
  );
}