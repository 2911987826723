import './InviteModal.scss';
import { stopBodyScroll } from "../../utils/functionUtils";
import { useEffect } from "react";
import { acquireOpportunity  } from '../../api/invite'
import { useRecoilState } from "recoil";
import { gainChanceState } from '../../state/atom';
import { throttle } from '../../utils/functionUtils'

/*
* @Description: 已邀请满人的弹窗组件
* @visible: 弹窗是否显示
* @setVisible: 展示/关闭弹窗的函数
* @version:
* @Author: hongtao
* @Date:
* @LastEditors: hongtao
* @LastEditTime:
*/
export default function InviteModal({visible, setVisible, inviteeCount, setGainChanceModalVisible}) {
  const [gainChanceInfo, setGainChanceInfo] = useRecoilState(gainChanceState);

  const closeModal = () => {
    setVisible(false);
  };

  const collectLotteryChance = async () => {
    const res = await acquireOpportunity()
    if (res&&res.code===0) {
      setGainChanceInfo({
        exchangeCount: `+${res.data.exchangeCount}`,
        exchangeCredits: `+${res.data.exchangeCredits}`
      })
      closeModal()
      setGainChanceModalVisible(true)
    }
  }

  useEffect(() => {
    stopBodyScroll(visible);
  }, [visible]);

  return (
    <div className="invite-modal" style={{ display: visible ? "flex" : "none" }}>
      <div className="modal-content-container bounceIn">
        <div className="invite-title">INVITE</div>
        <div className="invite-text1">当前有效邀请人数为</div>
        <div className="total-count-container">
          <div className="count-num">{inviteeCount}</div>
          <div className="people-count">人</div>
        </div>
        <div className="invite-text2">可以获得重新开舱机会！</div>
        <div className="button-group">
          <div className="button-left" onClick={closeModal}>取消</div>
          <div className="button-right" onClick={throttle(collectLotteryChance)}>立即领取开舱次数</div>
        </div>
      </div>
    </div>
  )
}