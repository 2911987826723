/*
 * @Descripttion: 统一的URL管理地址
 * @version:
 * @Author: hongtao
 * @Date:
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-07 19:52:16
 */
//
export const INVITE_INFO_URL = 'digitalAward/inviteInfo';
// 奖品二选一
export const SAVE_ONE_AWARD = 'digitalAward/saveOneAward';
//
export const GET_MESSAGE_SCENE = 'digitalAward/getInfoByScene';
// 用户领取游戏次数与积分
export const ACQUIRE_OPPORTUNITY_URL = 'digitalAward/acquireOpportunity';
// 活动规则描述
export const GET_RULE_TYPE = 'digitalAward/getRuleByType';
// 前置校验接口
export const PRE_CHECK = 'digitalAward/preCheck';
// 生成邀请海报
export const GENERATE_POSTER_URL = 'digitalAward/generatePoster'
// 兑换奖品后生成海报
export const GET_ALREADY_POSTER_URL = 'digitalAward/getAlreadyPoster'
// 数字藏品兑换查看接口
export const SHOW_COLLECTION_URL = 'digitalAward/showCollection'
// 奖品兑换
export const AWARD_EQUITY_URL = 'digitalAward/awardEquity'
// 数字藏品兑换
export const AWARD_COLLECTION_URL = 'digitalAward/awardCollection'
// 从邀请好友页点击兑换积分和次数
export const SHOW_EXCHANGE_URL = 'digitalAward/showExchange'
// 确认积分兑换
export const CONFIRM_SWAP_URL = 'digitalAward/deductCreditsExchangeCount'
// 邀请好友列表展示
export const INVITE_PAGE_URL = 'digitalAward/invitePage'