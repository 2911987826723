/*
 * @Descripttion: 
 * @version: 
 * @Author: caixin
 * @Date: 2022-09-19 16:21:56
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-19 22:26:05
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// document.querySelector('#moon').style.height = (window.innerWidth * 763) / 375 + "px";