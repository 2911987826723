/*
 * @Descripttion: 
 * @version: 
 * @Author: caixin
 * @Date: 2022-09-07 14:25:26
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-07 14:47:48
 */
const createElement = (type) =>  document.createElement(type);

export const generateHtml = (obj) => {
    let div = createElement('div');
    obj.forEach(item => {
        const el = createElement(item.type);
        el.innerText = item.text;
        div.appendChild(el);
    })
    return div.innerHTML;
}
