/*
 * @Descripttion: 
 * @version: 
 * @Author: caixin
 * @Date: 2022-09-06 09:59:39
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-22 17:14:13
 */
import "./App.scss";
import { RecoilRoot } from "recoil";
import Game from './pages/game';
import Result from './pages/result';
import { Routes, Route, BrowserRouter} from "react-router-dom";
import Invite from './pages/invitePage/invitePage'
import RedeemSuccess from './pages/redeemSuccess/redeemSuccess'
import VConsole from 'vconsole';
import { wxShare } from "./utils/functionUtils";
import MusicIcon from './components/MusicIcon/MusicIcon';

// or init with options
new VConsole({ theme: 'dark' });

function App() {

  // 防止localStorage的值被手动篡改
  // window.addEventListener('storage', (e) => {
  //   localStorage.setItem(e.key, e.oldValue);
  // });
  // 小程序分享
  wxShare();
  window.addEventListener('storage', (e) => {
    localStorage.setItem(e.key, e.oldValue);
  });

  return (
    <RecoilRoot>
      <div className="App">
        <BrowserRouter>
          <MusicIcon/>
          <Routes>
            <Route path="/activity-nft" element={<Game/>}/>
            <Route path="/activity-nft/result" element={<Result/>}/>
            <Route path="/activity-nft/invite" element={<Invite/>}/>
            <Route path="/activity-nft/redeem/success" element={<RedeemSuccess/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </RecoilRoot>
  );
}

export default App;
