/*
 * @Descripttion: 
 * @version: 
 * @Author: caixin
 * @Date: 2022-09-20 00:47:29
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-20 00:49:30
 */
function fetch_timeout(fetchPromise, timeout = 10000) {
    let abort = null;
    let abortPromise = new Promise((resolve, reject) => {
      abort = () => {
        return resolve({
          failCode: 504,
          msg: "请求超时！"
        });
      };
    });
   
    // 最快出结果的promise 作为结果
    let resultPromise = Promise.race([fetchPromise, abortPromise]);
    setTimeout(() => {
      abort();
    }, timeout);
   
    return resultPromise.then(res => {
        clearTimeout(timeout);
        return res;
     });
  }
   
  export default fetch_timeout;