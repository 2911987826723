/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import styles from './index.module.scss';
import "./index.scss";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { singleAwardState, multiAwardState, alreadyAwardState, replayStatus } from '../../state/atom';
import { saveOneAward } from "../../api/invite";
import { awardEquity } from '../../api/awards';
import InviteModal from '../../components/InviteModal/InviteModal';
import GainChanceModal from '../../components/GainChanceModal/GainChanceModal'
import DoubleCheckModal from '../../components/DoubleCheckModal/DoubleCheckModal'
import ConfirmSwapModal from '../../components/ConfirmSwapModal'
import { throttle } from 'utils/functionUtils';

export default function Result() {
  const alreadyAwardInfo = useRecoilValue(alreadyAwardState);
  const singleAward = useRecoilValue(singleAwardState);
  const multiAward = useRecoilValue(multiAwardState);

  useEffect(() => {
    const token = localStorage.getItem('Authorization')
    if (!token||(!singleAward&&!multiAward)) {
      const {origin} = window.location;
      const url = `${origin}/activity-nft/`;
      window.location.replace(url);
    }
    if (alreadyAwardInfo) {
      const token = localStorage.getItem('Authorization');
      const {origin}  = window.location;
      const url = `${origin}/activity-nft/?token=${token}&realLogin=true`;
      window.location.replace(url);
    }
  },[])

  if (multiAward) {
    return <PickOne/>
  } else if (multiAward === null) {
    return <OnlyOne/>
  }
}

function OnlyOne() {
  const navigate = useNavigate();
   // 获取全局的单个奖励数据
  const [singleAward, setSingleAward] = useRecoilState(singleAwardState);
  const setReplay = useSetRecoilState(replayStatus);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [gainChanceModalVisible, setGainChanceModalVisible] = useState(false);
  const [doubleCheckModalVisible, setDoubleCheckModalVisible] = useState(false);
  const [confirmSwapModalVisible, setConfirmSwapModalVisible] = useState(false);
  const setAlreadyAwardInfo = useSetRecoilState(alreadyAwardState);
  const setMultiAward = useSetRecoilState(multiAwardState);
  const [buttonLoading, setButtonLoading] = useState(false);
  const token = localStorage.getItem('Authorization')

  const goToAwardEquity = async () => {
    if (buttonLoading) {
      return false;
    }
    setButtonLoading(true);
    const res = await awardEquity(singleAward?.award?.drawAwardId)
    if (res&&res.data) {
      setAlreadyAwardInfo(res.data)
      setSingleAward(null)
      setMultiAward(null)
      setButtonLoading(false);
      setDoubleCheckModalVisible(false)
      navigate('/activity-nft/redeem/success')
    } else {
      setTimeout(()=>{
        setButtonLoading(false);
      }, 1500)
    }
  }

  const playOneceMore = async() => {
    await setReplay(true);
    navigate(`/activity-nft/?token=${token}&realLogin=true`)
  }

  useEffect(()=>{
    if (singleAward?.code===1) {
      setInviteModalVisible(true);
    }
  },[singleAward])
  return (
    <div className={`flex-col ${styles['only-one']}`}>
      <div className={styles["bottom-light"]}/>
      <InviteModal
        visible={inviteModalVisible} 
        setVisible={setInviteModalVisible}
        setGainChanceModalVisible={setGainChanceModalVisible}
        inviteeCount={singleAward?.totalCount}
      />
      <GainChanceModal
        visible={gainChanceModalVisible} 
        setVisible={setGainChanceModalVisible}
      />
      <DoubleCheckModal
        tipsText={'领取奖励后将结束游戏环节，\n有可能错失更高等级奖励。\n 确认现在领取吗？'}
        visible={doubleCheckModalVisible}
        setVisible={setDoubleCheckModalVisible}
        clickConfirm={goToAwardEquity}
      />
      <ConfirmSwapModal
        visible={confirmSwapModalVisible}
        setVisible={setConfirmSwapModalVisible}
        setGainChanceModalVisible={setGainChanceModalVisible}
      />
      <div className={`flex-col ${styles['group']}`}>
        <span className={`${styles['text']}`}></span>
        <div className={`${styles['text_10']}`}>中国探月太空兔-集度 ROBBIT 联名系列</div>
        <div className={`flex-col items-center ${styles['image-wrapper']}`}>
          <div className={styles.level_title}>
            <span className={styles.title}>{singleAward?.award?.desc}</span>
          </div>
          <img
            src={ singleAward?.award?.image || require('./assets/0c5982cec87c4181fc9cee427fe90d9e.png')}
            className={`${styles['image_1']}`}
          />
        </div>
      </div>
      <div className={styles.digital_collection}>
        <img className={styles.image1} src={require("./assets/digital-collection.png")} />
      </div>
      <div className={styles.equity_desc}>TA 拿出了一枚数字探索勋章 并送上<br />【{singleAward?.award?.equityDesc}】</div>
      {singleAward?.code === 0 && <div className={`flex-col items-center ${styles['button']}`} onClick={() => {navigate("/activity-nft/invite")}}><span className={`${styles['text_1']}`}>邀请好友 再次开舱</span></div>}
      {singleAward?.code === 1 && <div className={`flex-col items-center ${styles['button']}`} onClick={() => {setInviteModalVisible(true)}}><span className={`${styles['text_1']}`}>立即领取开舱次数</span></div>}
      {singleAward?.code === 2 && <div className={`flex-col items-center ${styles['button']}`} onClick={playOneceMore}><span className={`${styles['text_1']}`}>{`再玩一次（剩余 ${singleAward.totalCount} 次）`}</span></div>}
      <div className={`flex-row ${styles['group_1']}`}>
        {singleAward?.code === 0 && <div className={`flex-col items-center ${styles['text-wrapper']}`} onClick={() => {setConfirmSwapModalVisible(true)}}><span className={`${styles['text_2']}`}>积分开舱</span></div>}
        {(singleAward?.code === 1 || singleAward?.code === 2) && <div className={`flex-col items-center ${styles['text-wrapper']}`} onClick={() => {navigate("/activity-nft/invite")}}><span className={`${styles['text_2']}`}>邀请好友</span></div>}
        <div className={`flex-col items-center ${styles['text-wrapper']} ${styles['view_1']}`} onClick={() => {setDoubleCheckModalVisible(true)}}>
          <span className={`${styles['text_2']}`}>领取奖品</span>
        </div>
      </div>
      <div className={`flex-col ${styles['group_2']}`}>
        <span className={`${styles['text_5']} ${styles['text_6']}`}>·</span>
        <div className={`flex-row justify-between`}>
          <div className={`flex-col items-center ${styles['text-wrapper_1']}`}>
            <span className={`${styles['text_5']} ${styles['text_7']}`}>LE</span>
          </div>
          <div className={`flex-col ${styles['group_4']}`}>
            <span className={`${styles['text_5']} ${styles['text_8']}`}>robo-01</span>
            <span className={`${styles['text_5']} ${styles['text_9']}`}>lunar edition</span>
          </div>
        </div>
      </div>
      <div className={styles.notice}>* 请在活动有效期内完成奖品领取</div>
    </div>
  );
}

function PickOne() {
  const setSingleAward = useSetRecoilState(singleAwardState);
  const [multiAward, setMultiAward] = useRecoilState(multiAwardState);
  const [selectedId, setSelectedId] = useState(2);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [params, setParams] = useState({})
  const [lowLevel, setLowLevel] = useState(false);
  const [lowLevelModalVisible, setLowLevelModalVisible] = useState(false);

  const [awardId, setAwardId] = useState();
  // 点击二选一获取选择的奖励；
  const choseButtonOnclick = (e, id) => {
      const { item } = e.target.dataset;
      let curItem = JSON.parse(item);
      const { drawAwardId, awardLevel } = curItem;
      //
      const params = {drawAwardId: drawAwardId};
      setParams(params);
      setAwardId(drawAwardId);
      setSelectedId(id);
      console.log(awardLevel)
      console.log(multiAward[0].awardLevel)
      console.log(multiAward[1].awardLevel)
      if (awardLevel < multiAward[0].awardLevel || awardLevel < multiAward[1].awardLevel) {
        setLowLevel(true);
      } else {
        setLowLevel(false);
      }
      setButtonDisabled(false);
    }
  const isSelect = (id) => {
    if (selectedId === 2) {
      return ""
    } else if (selectedId === id) {
      return "selected"
    } else {
      return "unselected"
    }
  }
  const saveOne = async(params) => {
    const res = await saveOneAward(params);
    // 全局维护一个
    if(res && res.code === 0 && res.data) {
      setSingleAward(res.data);
      setMultiAward(null);
      setLowLevel(false);
    }
  }
  /**
   * @description:发起二选一的请求， 选择一个奖励成功之后，覆盖选择的奖励，展示单个奖励页面；
   * @return {*}
   * 
   */  
  const confirm = async () => {
    if (buttonDisabled === false) { 
      const id = {drawAwardId: awardId};
      setParams(params);
      if (lowLevel === true) {
        setLowLevelModalVisible(true)
      } else {
        saveOne(id);
      }
    }
  }

  return (
    <div className={`flex-col result-page ${styles['pick-one']}`}  >
      <div className={styles["bottom-light"]}/>
      <DoubleCheckModal
        tipsText={'选择后，另一只兔兔将永远告别哦\n确认选择TA吗 ？'}
        cancelText={'换一个'}
        visible={lowLevelModalVisible}
        setVisible={setLowLevelModalVisible}
        clickConfirm={() => {saveOne(params)}}
      />
      <div className={`flex-col items-start ${styles['group_2']}`}>
        <div className={`${styles['text']}`}></div>
        <span className={`${styles['text_2']}`}>请选择保留其中一只兔兔</span>
      </div>
      <div className={`flex-col ${styles['group_3']}`}>
        <div className={`flex-col items-end ${styles['group_4']}`}>
          <img
            src={require('./assets/daf98b0da2d0b87ad6733ff6c00a9d27.png')}
            className={`${styles['image_2']}`}
          />
          <div className={`flex-col items-start ${styles['section_2']}`}>
            <img
              src={require('./assets/3cd10b95f02bc2c6eedabb0c39949264.png')}
              className={`${styles['image_3']}`}
            />
            <div className={`flex-row justify-center ${styles['equal-division']}`}>
              <div className={`flex-col ${styles['equal-division-item']}`}>
                <span className={`text_4 ${styles['text_4']} ${isSelect(0)}`}>{multiAward[0].desc}</span>
                <div className={`items-center ${styles['image-wrapper']} ${isSelect(0)} image`}>
                  <img onClick={(e) => { choseButtonOnclick(e, 0) } }
                    data-item={JSON.stringify(multiAward[0])}
                    src={ multiAward[0]?.image || require('./assets/8fcebad4cc77ccc5e64f08a1518125d8.png')}
                    className={`${styles['image_4']}`}
                  />
                  <div className='background' />
                </div>
              </div>
              <div className={`flex-row ${styles['equal-division-item_1']}`}>
                <div className={`flex-row ${styles['group_5']}`}>
                  <span className={`text_5 ${styles['text_5']} ${isSelect(1)}`}>{multiAward[1].desc}</span>
                  <div className={`flex-col ${styles['section_12']} ${isSelect(1)} image`}>
                    <img onClick={(e) => { choseButtonOnclick(e, 1) }}
                      data-item={JSON.stringify(multiAward[1])} 
                      src={ multiAward[1]?.image || require('./assets/8fcebad4cc77ccc5e64f08a1518125d8.png')}
                    />
                    <div className='background' />
                  </div>
                </div>
                <div
                  className={`${styles['image_6']}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`flex-row ${styles['desc_group']}`}>
        <p className={`${styles['desc']} ${isSelect(0)}`}>{multiAward[0].equityDesc}</p>
        <p className={`${styles['desc']} ${isSelect(1)}`}>{multiAward[1].equityDesc}</p>
      </div>
      <div className={`flex-col items-center ${styles['button']} button ${buttonDisabled?"disabled":""}`} onClick={() => { throttle(confirm()) }}>
        <span className={`${styles['text_7']}`}>确认</span>
      </div>
      <div className={`flex-col ${styles['group_7']}`}>
        <span className={`${styles['text']} ${styles['text_8']}`}>·</span>
        <div className={`flex-row justify-between`}>
          <div className={`flex-col items-center ${styles['text-wrapper']}`}>
            <span className={`${styles['text']} ${styles['text_9']}`}>LE</span>
          </div>
          <div className={`flex-col ${styles['group_9']}`}>
            <span className={`${styles['text']} ${styles['text_10']}`}>robo-01</span>
            <span className={`${styles['text']} ${styles['text_11']}`}>lunar edition</span>
          </div>
        </div>
      </div>
    </div>
  );
}