import './redeemSuccess.scss';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PolicyModal from '../../components/PolicyModal/PolicyModal'
import PosterModal from '../../components/PosterModal/PosterModal'
import { useRecoilState } from "recoil";
import { alreadyAwardState } from '../../state/atom';
import { showCollection, awardCollection } from '../../api/awards'
import { getAlreadyPoster } from '../../api/poster'
import { centerToast } from "../../components/Toast/Toast";
import { throttle } from '../../utils/functionUtils'

/*
* @Description: 兑换成功页面，根据不同的奖品等级以及奖品是否领取的状态，做不同的渲染
* @version:
* @Author: hongtao
* @Date:
* @LastEditors: hongtao
* @LastEditTime:
*/
export default function RedeemSuccess() {
  const navigate = useNavigate();
  const { search }= window.location;
  const searchParams = new URLSearchParams(search);
  const [checkboxStatus, setCheckboxStatus] = useState(false)
  const [posterUrl, setPosterUrl] = useState('')
  const [policyModalVisible, setPolicyModalVisible] = useState(false);
  const [posterModalVisible, setPosterModalVisible] = useState(false);
  const [alreadyAwardInfo, setAlreadyAwardInfo] = useRecoilState(alreadyAwardState);
  const [showLoading, setShowLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const jiduAddress = 'https://m.jidutest.com'
  // const jiduAddress = process.env.REACT_APP_JIDU_ADDRESS;

  /**
   * @description: 点击checkbox,切换checkbox状态
   */
  const clickCheckBox = () => {
    setCheckboxStatus(!checkboxStatus)
  }

  /**
   * @description: 展示隐私条款弹窗
   */
  const goToPolicy = () => {
    setPolicyModalVisible(true);
  }

  /**
   * @description: 数字藏品兑换查看接口
   */
  const clickShowCollectionBtn = async () =>{
    if (buttonLoading) {
      return false;
    }
    setButtonLoading(true)
    const res = await showCollection()
    if (res&&res.data.bizId) {
      setButtonLoading(false)
      const token = localStorage.getItem('Authorization')
      window.location.href = `${jiduAddress}/hybrid/pages/prize_nft?id=${res.data.bizId}&token=${token}&realLogin=true`
    } else {
      setTimeout(()=>{
        setButtonLoading(false);
      }, 1500)
    }
  }

  const clickCollectBtn = async () =>{
    if (buttonLoading) {
      return false;
    }
    setButtonLoading(true)
    const res = await awardCollection(alreadyAwardInfo?.alreadyAwardId)
    const token = localStorage.getItem('Authorization')
    if(res&&res.data&&res.data.needAuthenticated) {
      setButtonLoading(false)
      const userId = localStorage.getItem('userId')
      const { origin } = window.location;
      let originUrl = ''
      originUrl = origin.replace(/\//g, '%2F')
      originUrl = originUrl.replace(/:/g, '%3A')
      window.location.href = `${jiduAddress}/hybrid/pages/idcard_auth?token=${token}&userId=${userId}&realLogin=true&redirectUrl=${originUrl}%2Factivity-nft%2Fredeem%2Fsuccess`
    } else if (res&&res.data&&res.data.bizId) {
      const newAlreadyAwardInfo = {...alreadyAwardInfo}
      newAlreadyAwardInfo.collectionStatus = res.data.collectionStatus
      setAlreadyAwardInfo(newAlreadyAwardInfo)
      setButtonLoading(false)
      window.location.href = `${jiduAddress}/hybrid/pages/prize_nft?id=${res.data.bizId}&token=${token}&realLogin=true`
    } else {
      setTimeout(()=>{
        setButtonLoading(false);
      }, 1500)
    }
  }

  /**
   * @description: 无数藏的情况下，展示兑换成功海报
   */
  const showPoster = () => {
    if (showLoading) {
      return false
    }
    if (posterUrl) {
      setPosterModalVisible(true)
    } else {
      setShowLoading(true)
    }
  }

  const showMyAwards = () => {
    const token = localStorage.getItem('Authorization')
    window.location.href = `${jiduAddress}/hybrid/pages/prize_list?token=${token}&realLogin=true`
  }

  const showMyAwardsMiniProgramPage = () => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('miniprogram') > -1) {
      window.wx.miniProgram.navigateTo({
        url:'/pages/scoreDetail',
        success(res){
          console.log('navigateTo success==>', res)
        },
        fail(res) {
          console.log('navigateTo fail==>', res)
        }
      });
    } else {
      centerToast('请到集度小程序查看')
    }
  }
  useEffect(() => {
    const token = localStorage.getItem('Authorization')
    if (!token||!alreadyAwardInfo) {
      const {origin} = window.location;
      const url = `${origin}/activity-nft/`;
      window.location.replace(url);
      return false;
    }
    if (alreadyAwardInfo?.collectionStatus===0) {
      const fetchPosterInfo = async() => {
        let codeScene = 'H5'
        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('miniprogram') > -1) {
          codeScene = 'MINI_PROGRAM'
        }
        const posterInfo = await getAlreadyPoster(codeScene)
        setPosterUrl(posterInfo.data)
      }
      fetchPosterInfo()
    }
  }, [])

  useEffect(() => {
    if(showLoading===true) {
      setShowLoading(false)
      setPosterModalVisible(true)
    }
  },[posterUrl])

  useEffect(() => {
    const goToCollectStep = async() => {
      const res = await awardCollection(alreadyAwardInfo?.alreadyAwardId)
      if (res&&res.data.bizId) {
        const newAlreadyAwardInfo = {...alreadyAwardInfo}
        newAlreadyAwardInfo.collectionStatus = res.data.collectionStatus
        setAlreadyAwardInfo(newAlreadyAwardInfo)
        const token = localStorage.getItem('Authorization')
        window.location.href = `${jiduAddress}/hybrid/pages/prize_nft?id=${res.data.bizId}&token=${token}&realLogin=true`
      }
    }
    const fourteenAgeFlag = searchParams.get("fourteenAgeFlag");
    if (fourteenAgeFlag?.toString()==='true') {
      goToCollectStep()
    } else if (fourteenAgeFlag?.toString()==='false') {
      centerToast('您未满18岁不能参加活动')
    }
  },[])

  return (
    <div className="redeem-success-page">
      <PolicyModal visible={policyModalVisible} setVisible={setPolicyModalVisible}/>
      <PosterModal visible={posterModalVisible} posterUrl={posterUrl} setVisible={setPosterModalVisible}/>
      <div className="robbit-title">ROBBIT</div>
      <div className="main-title">中国探月太空兔-集度 ROBBIT 联名系列</div>
      <div className="nft-back-container">
        <div className="nft-title-container">
          <div className="level-text">{alreadyAwardInfo?.desc}</div>
        </div>
        <img className="nft-image" src={alreadyAwardInfo?.posterUrl} alt="" />
        <div className="digital-collection-text"></div>
      </div>
      <div className="equity-desc">{alreadyAwardInfo?.equityDesc}</div>
      {alreadyAwardInfo&&alreadyAwardInfo.collectionStatus===1?
        checkboxStatus?
        <div className="success-page-button-collect" onClick={clickCollectBtn}>领取数字藏品</div>:
        <div className="success-page-button-collect disabled-bg" onClick={()=>centerToast('请先阅读并同意数字藏品领取须知')}>领取数字藏品</div>
      :null}
      {alreadyAwardInfo&&alreadyAwardInfo.collectionStatus===2?<div className="success-page-button-check-or-poster" onClick={throttle(clickShowCollectionBtn)}>查看数字藏品</div>:null}
      {alreadyAwardInfo&&alreadyAwardInfo.collectionStatus===0?
        (showLoading?
        <div className="success-page-button-check-or-poster loading-status">
          <div>海报加载中</div>
          <div className="loading-dots"></div>
        </div>:
        <div className="success-page-button-check-or-poster" onClick={throttle(showPoster)}>保存海报</div>)
      :null}
      {alreadyAwardInfo&&alreadyAwardInfo.awardType===0?
        <div className={`success-page-button-second ${alreadyAwardInfo&&alreadyAwardInfo.collectionStatus===1?'button-extra-style':''}`} onClick={throttle(showMyAwardsMiniProgramPage)}>查看我的奖品</div>:
        <div className={`success-page-button-second ${alreadyAwardInfo&&alreadyAwardInfo.collectionStatus===1?'button-extra-style':''}`} onClick={throttle(showMyAwards)}>查看我的奖品</div>
      }
      {alreadyAwardInfo&&alreadyAwardInfo.collectionStatus===1?
        <div className="policy-container">
          {checkboxStatus?
          <div className="checkbox-checked" onClick={clickCheckBox}></div>:
          <div className="checkbox-uncheck" onClick={clickCheckBox}></div>}
          <div className="policy-text">我已阅读并同意</div>
          <div className="policy-button" onClick={goToPolicy}>《数字藏品领取须知》</div>
        </div>
      :null}
      <div className="bottom-icon"></div>
      <div className="bottom-text"></div>
      <div className="bottom-light"></div>
      {alreadyAwardInfo&&alreadyAwardInfo.collectionStatus===2?
        <div className="bottom-policy-button" onClick={goToPolicy}>《数字藏品领取须知》</div>
        :null
      }
    </div>
  )
}