import './DoubleCheckModal.scss';
import { useEffect } from "react";
import { stopBodyScroll, throttle } from "../../utils/functionUtils";

export default function DoubleCheckModal({tipsText,visible,setVisible,clickConfirm,cancelText}) {
  useEffect(() => {
    stopBodyScroll(visible);
  }, [visible]);

  return (
    <div className="double-check-modal" style={{ display: visible ? "flex" : "none" }}>
      <div className="modal-content-container bounceIn">
        <div className="top-tips-text">{tipsText}</div>
        <div className="buttons-group">
          <div className="button-cancel" onClick={()=>setVisible(false)}>{cancelText?cancelText:"取消"}</div>
          <div className="button-confirm" onClick={throttle(clickConfirm)}>确认</div>
        </div>
      </div>
    </div>
  )
}