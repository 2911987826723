import './introModal.scss';

/*
* @Description: 首页intro弹窗
* @mainText: intro内容传参
* @version: 
* @Author: hongtao
* @Date:
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-13 19:22:33
*/
export default function IntroModal(props) {
  const {ruleIntro, setRuleIntro, mainText, title } = props;
  return (
    <div className="intro-modal" style={{ display: ruleIntro ? "flex" : "none" }}>
      <div className="skip-button" onClick={() => setRuleIntro(false)}>跳过</div>
      <div className="robbit-title"></div>
      <div className="intro-main-title">{ title }</div>
      <div className="text-outer-container">
        {/* <div className="text-content">{mainText}</div> */}
        
        <div className="text-content" dangerouslySetInnerHTML={{ __html: mainText}}></div>
        {/*  */}
      </div>
      <div className="digital-collection-text"></div>
      <div className="start-discover-button"  onClick={() => setRuleIntro(false)}>即刻探索</div>
    </div>
  )
}