/*
 * @Descripttion: 
 * @version: 
 * @Author: caixin
 * @Date: 2022-09-08 15:55:09
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-20 12:38:40
 */
// 跳转路径map
export const NAVIGATE_MAP = {
    1: "/activity-nft/redeem/success",
    2: "/activity-nft/result?double=true",
    3: "/activity-nft/result"
  };
// 按钮展示信心map
export const Message_Map = {
  0: "开始游戏",
  1: "查看奖品",
  2: "继续游戏",
  3: "继续游戏"
}