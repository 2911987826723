import http from '../utils/http'
import { GENERATE_POSTER_URL, GET_ALREADY_POSTER_URL } from './urls'

/*
 * @Descripttion: 海报展示相关接口请求
 * @version: 
 * @Author: hongtao
 * @Date:
 * @LastEditors: hongtao
 * @LastEditTime: 
 */

/**
 * @description: 获取邀请海报url
 * @param {}
 * @return {*}
 */
export const generatePoster = (codeScene) => {
  return http.request("GET", GENERATE_POSTER_URL, {codeScene})
}

/**
 * @description: 获取兑换奖品后的海报url
 * @param {*}
 * @return {*}
 */
export const getAlreadyPoster = (codeScene) => {
  return http.request("GET", GET_ALREADY_POSTER_URL, {codeScene})
}