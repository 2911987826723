import "./Button.scss";
import { throttle } from "../../utils/functionUtils";

/*
公用 button组件
    content: 按钮文字 string
    isConfirm：是否为确认按钮 boolean
    clickEvent: 点击事件 function
    style: 自定义样式 CSSProperties
*/

export default function Button(props) {
  const { content, isConfirm, clickEvent, style } = props;

  const finalStyle = {
    height: "5.4vh",
    width: "36.7vw",
    background: isConfirm ? "#824DFC" : "transparent",
    border: isConfirm ? "none" : "1px solid #824DFC",
    ...style,
  };

  return (
    <div
      className="button-container"
      style={finalStyle}
      onClick={throttle(clickEvent, 1000)}
    >
      {content}
    </div>
  );
}
