import './index.scss';
import { useEffect } from "react";
import { stopBodyScroll, throttle } from "../../utils/functionUtils";
import { confirmSwap } from 'api/result';
import { useRecoilState } from 'recoil';
import { gainChanceState, drawAwardCountState } from "../../state/atom";

export default function ConfirmSwapModal({visible, setVisible, setGainChanceModalVisible}) {
  const [gainChanceInfo, setGainChanceInfo] = useRecoilState(gainChanceState);
  const [drawAwardCount, setDrawAwardCount] = useRecoilState(drawAwardCountState);
  useEffect(() => {
    stopBodyScroll(visible);
  }, [visible]);

  const clickConfirm = async() => {
    const res = await confirmSwap();
    if (res.code === 0) {
      setDrawAwardCount(res.data);
      setGainChanceInfo({
        exchangeCount: "+1",
        exchangeCredits: "-300"
      })
      setVisible(false);
      setGainChanceModalVisible(true)
    }
  }

  return (
    <div className="confirm-swap-modal" style={{ display: visible ? "flex" : "none" }}>
      <div className="content-container bounceIn">
        <div className="top-tips">
          <div className='points'>
            <img className="icon" src={require("./assets/money.png")} alt="" />
            <div className='number'>-300</div>
          </div>
          <div className="text">花 300 积分再开一次</div>
        </div>
        <div className="buttons-group">
          <div className="button-cancel" onClick={()=>setVisible(false)}>取消</div>
          <div className="button-confirm" onClick={throttle(clickConfirm)}>确认积分开舱</div>
        </div>
      </div>
    </div>
  )
}