/*
 * @Descripttion:
 * @version:
 * @Author: caixin
 * @Date: 2022-08-31 19:07:03
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-22 11:55:41
 */
// 全局禁用滑动方法
export function stopBodyScroll(isStop) {
  let bodyEl = document.body;
  let top = window.scrollY;

  if (isStop) {
    bodyEl.style.position = "fixed";
    bodyEl.style.overflow = "hidden";
    // bodyEl.style.top = -top + "px";
  } else {
    const top = bodyEl.style.top;
    bodyEl.style.position = "";
    bodyEl.style.overflow = "";
    // window.scrollTo(0, -parseInt(top));
  }
}

//节流函数
export function throttle(fn, wait=1000) {
  let previous = 0;
  return function (...args) {
    let now = +new Date();
    if (now - previous > wait) {
      previous = now;
      fn.apply(this, args);
    }
  };
}

/**
 * @description: 同步函数管道
 * @param {*} fns
 * @return {*}
 */
export const pipeline = (fns) => {
  const [first, ...others] = fns;
  return (...args) =>
    others.reduce((acc, cur, index) => cur(acc), first(...args));
};

/**
 * @description: 异步函数管道
 * @param {*} fns
 * @return {*}
 */
export const asyncPipeline = (fns) => {
  const [first, ...others] = fns;
  return (...args) =>
    others.reduce(async (acc, cur, index) => {
      return acc
        .then((res) => cur(res))
        .catch((err) => {
          throw new Error(err);
        });
    }, first(...args));
};

/**
 * @description: 装饰器函数
 * @param {*} fn
 * @param {*} beforeFn
 * @return {*}
 */
export const before = (fn, beforeFn) => {
  return function (...args) {
    const res = beforeFn(...args);
    if (res) {
      return fn(...args);
    }
  };
};
/**
 * @description: 装饰器函数
 * @param {*} fn
 * @param {*} afterFn
 * @return {*}
 */
export const after = (fn, afterFn) => {
  return function (...args) {
    const res = fn(...args);
    if (res) {
      afterFn(...args);
      return res;
    }
  }
}

/**
 * @description: 设置storage
 * @param {*} key
 * @param {*} value
 * @return {*}
 */
export const setStorage = (key, value) => {
  localStorage.setItem(key, value);
}
const isObjStr = (str) =>{
  return str ? str.startsWith('{') : false;
} 
/**
 * @description: 获取storage
 * @param {*} key
 * @return {*}
 */
export const getStorage = (key) =>{
  const val = localStorage.getItem(key);
  return isObjStr(val)? JSON.parse(val): val;
}
/**
 * @description: 删除某个存储
 * @param {*} key
 * @return {*}
 */
export const clearStorage = (key) => localStorage.clearStorage(key)

/**
 * @description: 清除所有localStorage
 * @return {*}
 */
 export const clearAllStorage = () => localStorage.clear();
 /**
  * @description: 
  * @return {*}
  */
 export const setMoonVisible = (visible = true) => {
  const moon = document.getElementById("moon");
  if (moon) {
    moon.style.display = visible?'block':'none';
  }
}
/**
 * @description: 判断moon 是否是显示的；
 * @return {*}
 */
export const isMoonVisible = () => {
  const moon = document.getElementById("moon");
  return moon && moon.style.display === 'block';
}

/**
 * @description: 传给小程序分享的相关信息
 * @return {*}
 */
export const wxShare = () => {
  //
  const activity_id = getStorage('activity_id');
  const event_source_jidu = getStorage('esj');
  const { origin } = window.location;
  window.wx.miniProgram.postMessage({
    data: {
      type: 'share',
      data: {
          path: `${origin}/activity-nft/?activity_id=${activity_id}&event_source_jidu=${event_source_jidu}`,  // 要分享的域名, 有 activity_id(打开app时url获取) 和 event_source_jidu(固定值, 产品给)
          title: '',   // (产品给)
          imageUrl: ''  // (产品给)
      }
    }
  })
}
