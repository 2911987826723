import http from '../utils/http'
import { SHOW_EXCHANGE_URL } from './urls'

/*
 * @Descripttion: 获取从邀请好友页点击兑换 积分和次数
 * @version:
 * @Author: hongtao
 * @Date:
 * @LastEditors: hongtao
 * @LastEditTime:
 */

/**
 * @description: 单个奖品盲盒展示页的信息获取
 * @param {*}
 * @return {*}
 */
export const showExchange = () => {
  return http.request("GET", SHOW_EXCHANGE_URL)
}



