import './PolicyModal.scss';
import { useEffect, useState } from "react";
import { stopBodyScroll } from "../../utils/functionUtils";
import { getRuleByType } from "../../api/invite";
import { generateHtml } from "../../utils/parse";

/*
* @Description: 隐私条款的弹窗组件
* @visible: 弹窗是否显示
* @setVisible: 展示/关闭弹窗的函数
* @version:
* @Author: hongtao
* @Date:
* @LastEditors: hongtao
* @LastEditTime:
*/
export default function PolicyModal({visible, setVisible}) {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const closeModal = () => {
    setVisible(false);
  };

  const contentText = `本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：、场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：

  场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：
  、场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：
  
  场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：
  、场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：
  、场外交易、欺诈等非法行为或以任何非方法使用。 `
  useEffect(() => {
    stopBodyScroll(visible);
  }, [visible]);

  useEffect(() => {
    const fetchPosterInfo = async() => {
      const res = await getRuleByType({ ruleType: 2 });
      setTitle(res.data.title)
      setContent(generateHtml(res.data.content))
    }
    fetchPosterInfo()
  },[])

  return (
    <div className="policy-modal" style={{ display: visible ? "flex" : "none" }}>
      <div className="icon-close" onClick={closeModal}></div>
      <div className="policy-title">{title}</div>
      <div className="content-text" dangerouslySetInnerHTML={{ __html: content}}></div>
    </div>
  )
}