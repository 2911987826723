/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import styles from "./index.module.scss";
import Modal from "../../../../components/Modal/Modal.jsx";
import { useNavigate } from "react-router-dom";
import http from "utils/http";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { activityInfoState, singleAwardState, multiAwardState, replayStatus, drawAwardCountState, doubleResultState } from '../../../../state/atom';
import { throttle } from "../../../../utils/functionUtils";

export default function MysteryBoxModal(props) {
  const navigate = useNavigate();
  const [type, setType] = useState("box");
  const activityInfo = useRecoilValue(activityInfoState);
  const setSingleAward = useSetRecoilState(singleAwardState);
  const [drawAwardCount, setDrawAwardCount] = useRecoilState(drawAwardCountState);
  const setMultiAward = useSetRecoilState(multiAwardState);
  const setReplay = useSetRecoilState(replayStatus);
  const setDoubleResult = useSetRecoilState(doubleResultState);
  const [buttonLoading, setButtonLoading] = useState(false);

  const resultMap = {
    2: true,
    3: false
  }
  
  
  const openMysteryBox = async () => {
    if (buttonLoading) {
      return
    } else {
      setButtonLoading(true);
      // setMoonVisible(false);
      const data = {
        inviterId: activityInfo,
        activityId: "",
      };
      // console.log('activityInfo===>', activityInfo);
      await setReplay(false)
      const res = await http.request("POST","digitalAward/draw", activityInfo || data);
      props.reset();
      if (res && res?.data) {
        const { showType, multiSelectAward, singlePrepareAward } = res.data;
        setSingleAward(singlePrepareAward);
        setMultiAward(multiSelectAward);
        setDrawAwardCount(drawAwardCount - 1);
        setDoubleResult(resultMap[showType]);
        await setTimeout(() => {
          navigate("/activity-nft/result");
        }, 5500);
        setType("loading");
        setButtonLoading(false);
      } else {
        props.setOpen(false);
      }
    }
  };

  const box = (
    <div className={`flex-col ${styles["box"]}`}>
      <div className={`flex-col  ${styles["group"]}`}>
        <div className={`${styles["text"]}`}></div>
        <div className={`flex-col ${styles["section_1"]}`}>
          <div className={`flex-col`}>
            <div className={`flex-row justify-between`}>
              <img
                src={require("./assets/10e960d5bc5f9e6c7ec0b8d08f33051e.png")}
                className={`${styles["image_1"]}`}
              />
              <img
                src={require("./assets/cc953184d4b9fe5eab234b78b00fd9a7.png")}
                className={`${styles["image_3"]}`}
              />
            </div>
            <span className={`${styles["text_1"]} ${styles["text_2"]}`}>
              lunar
            </span>
            <div className={`flex-row ${styles["group_3"]}`}>
              <span className={`${styles["text_1"]} ${styles["text_3"]}`}>
                S61=2
              </span>
              <span className={`${styles["text_1"]} ${styles["text_4"]}`}>
                s63=3
              </span>
            </div>
            <div className={`flex-col items-start ${styles["group_4"]}`}>
              <span className={`${styles["text_1"]} ${styles["text_5"]}`}> </span>
              <div className={`flex-col items-end ${styles["group_5"]}`}>
                <img
                  src={require("./assets/003e7fc17427b7cdebe1536909fda8b4.png")}
                  className={`${styles["image_4"]}`}
                />
                <span className={`${styles["text_6"]}`}>
                  0X007567JD 67 68 69{" "}
                </span>
              </div>
            </div>
          </div>
          <div className={`flex-row justify-between`}>
            <img
              src={require("./assets/c6508fcefe7076c366872021e2a113bd.png")}
              className={`${styles["image_5"]}`}
            />
            <img
              src={require("./assets/79f72f08b0b95bdc1efc7eafe5ae3b28.png")}
              className={`${styles["image_1"]}`}
            />
          </div>
        </div>
        <div className={`flex-row justify-end ${styles["divider"]}`}>
          <div className={`flex-row ${styles["group_7"]}`}>
            <span className={`${styles["text_1"]} ${styles["text_7"]}`}>
              digital collection 1.0
            </span>
          </div>
        </div>
      </div>
      <div className={`flex-col ${styles["group_8"]}`}>
        <div className={`flex-row justify-between`}>
        </div>
        <span className={`${styles["text_8"]}`}>
          你偶遇了这枚探月舱
        </span>
      </div>
      <div className={`flex-col items-center ${styles["button"]}`} onClick={throttle(openMysteryBox)}>
        <span className={`${styles["text_9"]}`}>
          请敲舱门
        </span>
      </div>
    </div>
  );

  const loading = (
    <div className={`flex-col items-center ${styles["loading"]}`}>
      <img
        src={require("./assets/2fc116bbb339a9b00cb5fd86bb18ad81.gif")}
        className={`${styles["image"]}`}
      />
      <span className={`${styles["text"]}`}>舱门开启中...</span>
      <div className={styles["bottom-light"]}/>
    </div>
  );

  switch (type) {
    case "box":
      return (
        <Modal
          content={box}
          visible={props.open}
          setVisible={props.setOpen}
          withCloseIcon
        />
      );
    case "loading":
      return (
        <Modal
          content={loading}
          visible={props.open}
          setVisible={props.setOpen}
        />
      );
    default:
      return null;
  }
}
