import http from '../utils/http'
import { AWARD_EQUITY_URL, SHOW_COLLECTION_URL, AWARD_COLLECTION_URL } from './urls'

/*
 * @Descripttion: 奖品相关接口请求
 * @version: 
 * @Author: hongtao
 * @Date:
 * @LastEditors: hongtao
 * @LastEditTime: 
 */

/**
 * @description: 奖品兑换接口
 * @param {drawAwardId:'123'}
 * @return {*}
 */
export const awardEquity = (drawAwardId) => {
  return http.request("POST", AWARD_EQUITY_URL, {
    drawAwardId
  })
}

/**
 * @description: 数藏兑换接口
 * @param {drawAwardId:'123'}
 * @return {*}
 */
export const awardCollection = (drawAwardId) => {
  return http.request("POST", AWARD_COLLECTION_URL, {
    drawAwardId
  })
}

/**
 * @description: 数字藏品兑换查看接口
 * @param {*}
 * @return {*}
 */
export const showCollection = () => {
  return http.request("GET", SHOW_COLLECTION_URL)
}
