/*
 * @Descripttion: 
 * @version: 
 * @Author: caixin
 * @Date: 2022-08-29 16:31:45
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-08 18:01:05
 */
import { atom } from 'recoil';

const localStorageEffect = key => ({setSelf, onSet}) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};

export const textState = atom({
  key: 'textState', // unique ID (with respect to other atoms/selectors)
  default: 'test for jidu', // default value (aka initial value)
  effects: [
    localStorageEffect('text_state')
  ]
});

// 获取扫小程序二维码海报进入场景存储的全局数据
export const activityInfoState = atom({
  key: "activityInfo",
  default: {},
  effects: [
    localStorageEffect('activity_info_state')
  ]
});
export const drawAwardCountState = atom({
  key: "drawAwardCount",
  default: 0,
  effects: [
    localStorageEffect('draw_award_count_state')
  ]
});
// 单个奖励
export const singleAwardState = atom({
  key: "singleAwardInfo",
  default: null,
  effects: [
    localStorageEffect('single_award_info_state')
  ]
});
// 多个奖励
export const multiAwardState = atom({
  key: "multiAwardInfo",
  default: null,
  effects: [
    localStorageEffect('multi_award_info_state')
  ]
});

export const alreadyAwardState = atom({
  key: "alreadyAwardInfo",
  default: null,
  effects: [
    localStorageEffect('already_award_info_state')
  ]
});

export const checkInfoState = atom({
  key: 'checkInfo',
  default: null,
  effects: [
    localStorageEffect('check_info_state')
  ]
})

export const gainChanceState = atom({
  key: 'gainChanceInfo',
  default: {
    exchangeCount: '',
    exchangeCredits: ''
  },
  effects: [
    localStorageEffect('gain_chance_state')
  ]
})

export const replayStatus = atom({
  key: 'replay',
  default: false
})

export const doubleResultState = atom({
  key: 'doubleResult',
  default: false,
  effects: [
    localStorageEffect('double_result_state')
  ]
})
