/*
 * @Descripttion: 
 * @version: 
 * @Author: caixin
 * @Date: 2022-09-16 09:54:42
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-20 12:18:07
 */
import './GainChanceModal.scss';
import { useNavigate } from 'react-router-dom';
import { stopBodyScroll } from "../../utils/functionUtils";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { gainChanceState, replayStatus } from '../../state/atom';

export default function GainChanceModal({visible,setVisible}) {
  const navigate = useNavigate();
  const [gainChanceInfo, setGainChanceInfo] = useRecoilState(gainChanceState);
  const [replay, setReplay] = useRecoilState(replayStatus);

  useEffect(() => {
    stopBodyScroll(visible);
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const token = localStorage.getItem('Authorization')
      setTimeout(async()=>{
        await setVisible(false);
        await setReplay(true);
        navigate(`/activity-nft/?token=${token}&realLogin=true`)
      }, 2000)
    }
  },[visible])

  return (
    <div className="gain-chance-modal" style={{ display: visible ? "flex" : "none" }}>
      <div className="modal-content-container bounceIn">
        <div className="game-icon"></div>
        <div className="game-info-text">游戏次数 {gainChanceInfo?.exchangeCount}</div>
        <div className="game-info-text" style={{marginBottom: '50vw'}}>积分 {gainChanceInfo?.exchangeCredits}</div>
      </div>
    </div>
  )
}