import './PosterModal.scss';
import { stopBodyScroll } from "../../utils/functionUtils";
import { useEffect } from "react";

/*
* @Description: 通用的海报展示弹窗
* @visible: 弹窗是否显示
* @setVisible: 展示/关闭 弹窗的函数
* @posterUrl: 海报图片的url路径
* @version: 
* @Author: hongtao
* @Date:
* @LastEditors: hongtao
* @LastEditTime:
*/
export default function PosterModal({posterUrl,visible,setVisible}) {
  const closeModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    stopBodyScroll(visible);
  }, [visible]);

  return (
    <div className="poster-modal" style={{ display: visible ? "flex" : "none" }}>
      <div className="poster-content">
        <div className="icon-back" onClick={closeModal}></div>
        <img className="poster-img" src={posterUrl} mode="widthFix" alt=""/>
        <div className="bottom-tips-text">长按图片下载保存</div>
      </div>
    </div>
  )
}