import "./Modal.scss";
import Button from "../Button/Button";
import { stopBodyScroll } from "../../utils/functionUtils";
import { useEffect } from "react";
import CloseIcon from "../../assets/common/close-icon.png";

/*
公用 modal组件
    content: 弹框内容 string || react node
    visible: 是否可见 boolean
    setVisible: 控制弹窗开关 function
    withButton：是否需要按钮 boolean
    withCube: 是否需要装饰icon boolean
    withCloseIcon: 是否需要左上角关闭icon boolean
    style: 按钮相关样式 CSSProperties  {yesBtnStyle, noBtnStyle, buttonSetContainer, contentStyle}
    yesBtnEvent: 确认按钮事件 function
    noBtnEvent: 取消按钮事件 function
*/

export default function Modal(props) {
  const {
    visible,
    setVisible,
    content,
    withButton,
    withCube,
    withCloseIcon,
    style,
    yesBtnEvent,
    noBtnEvent,
    yesBtnText,
    noBtnText,
  } = props;

  const closeModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    stopBodyScroll(visible);
  }, [visible]);

  return (
    <div
      className="modal-container"
      style={{ display: visible ? "" : "none" }}
    >
      {withCloseIcon ? (
        <img
          src={CloseIcon}
          alt=""
          className="close-icon"
          onClick={closeModal}
        />
      ) : null}
      {withCube ? <div className="cube" /> : null}
      {typeof content === "string" ? (
        <div className="content" style={style.contentStyle}>
          {content}
        </div>
      ) : (
        <div className="bounceIn">
          {content}
        </div>
      )}
      {withButton ? (
        <div className="button-set" style={style.buttonSetContainer}>
          <Button
            content={noBtnText? noBtnText :"换一个"}
            style={{ width: "152px", height: "40px", ...style.noBtnStyle }}
            clickEvent={noBtnEvent}
          />
          <Button
            content={yesBtnText? yesBtnText: "确认"}
            isConfirm={true}
            style={{ width: "152px", height: "40px", ...style.yesBtnStyle }}
            clickEvent={yesBtnEvent}
          />
        </div>
      ) : null}
    </div>
  );
}
