import './RuleModal.scss';
import { useEffect } from "react";
import { stopBodyScroll } from "../../utils/functionUtils";

/*
* @Description: 隐私条款的弹窗组件
* @visible: 弹窗是否显示
* @setVisible: 展示/关闭弹窗的函数
* @version:
* @Author: hongtao
* @Date:
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-07 16:15:57
*/
export default function RuleModal({visible, setVisible, title, contentText}) {
  const closeModal = () => {
    setVisible(false);
  };
  // console.log('contentText===>', contentText);

  /* const contentText = `本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：、场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：

  场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：
  、场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：
  
  场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：
  、场外交易、欺诈等非法行为或以任何非方法使用。 本系列作品，激发创作灵感而生，记录这无邪梦境中超现实主义的梦境氛围，以无与伦比的色彩想象力造就独立自由的梦境世界。用户点击月球开盲盒，盲盒共包含 8 个等级，其中：
  、场外交易、欺诈等非法行为或以任何非方法使用。 ` */
  useEffect(() => {
    stopBodyScroll(visible);
  }, [visible]);
  return (
    <div className="rule-modal" style={{ display: visible ? "flex" : "none" }}>
      <div className="icon-close" onClick={closeModal}></div>
      <div className="policy-title">
        {/* { title || '隐私条款' } */}
      </div>
     {/*  <div className="content-text">
        {contentText}
      </div> */}
      <div className="content-text" dangerouslySetInnerHTML={{ __html: contentText}}>
        {/* {contentText} */}
      </div>
    </div>
  )
}