/*
 * @Descripttion: 封装集度侧登录sdk
 * @version:
 * @Author: caixin
 * @Date: 2022-09-02 13:36:35
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-19 17:34:21
 */

/**
 * @description: 登录操作
 * @param {*} url
 * @param {*} params
 * @return {*}
 */


const Login = new window.JiduLogin({
  env: "test", // String  环境   dev|test|prod
  // ...可能还有别的参数，后面看情况再加
  isLocalDebug: true,
  callback: () => {
  }, // Function  初始化后的回调函数
});
export default  Login;
