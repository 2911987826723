/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import MysteryBoxModal from "./components/MysteryBoxModal";
import Button from "../../components/Button/Button";
import IntroModal from "../../components/introModal/introModal";
import RuleModal from "../../components/RuleModal/RuleModal";
import { centerToast } from "../../components/Toast/Toast";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  activityInfoState,
  singleAwardState,
  multiAwardState,
  alreadyAwardState,
  replayStatus,
  drawAwardCountState,
} from "../../state/atom";
import { getInfoByScene, getRuleByType, preCheck } from "../../api/invite";
import Login from "../../utils/login";
import {throttle, setStorage, getStorage, clearStorage, clearAllStorage } from "../../utils/functionUtils";
import { generateHtml } from "../../utils/parse";
import { NAVIGATE_MAP, Message_Map } from "./index.const";
import invitePageBg from '../../assets/invite/invite-page-bg.png'
import redeemSuccessBg from '../../assets/redeemSuccess/redeem-success-bg.png'
import bgShort from '../../assets/common/bg-short.png';
import bottomLight from '../../assets/common/bottom-light.png'
// import { storageStore } from "storage/storage.model";

export default function Game() {
  const [mysteryOpen, setMysteryOpen] = useState(false);
  const [buttonText, setButtonText] = useState("开始游戏");
  const [intro, setIntro] = useState(true);
  const [visible, setVisible] = useState(false);
  const [ruleIntro, setRuleIntro] = useState(false);
  const [mainText, setMainText] = useState();
  const [ruleText, setRuleText] = useState();
  const [ruleTitle, setRuleTitle] = useState("中国探月太空兔-集度ROBBIT");
  const [code, setCode] = useState();

  const [loading, setLoading] = useState(true);
  const [tips, setTips] = useState(false);

  const navigate = useNavigate();
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);

  const startGame = () => {
    if (window.frames[0]) {
      window.frames[0].postMessage("startGame", "*");
    }
    document.getElementById("moon").contentWindow.startGame();
  };

  const reset = async() => {
    var iframeWindow = document.getElementById('moon').contentWindow;
    console.log('iframeWindow ===>', iframeWindow);
    console.log('iframeWindow ===>', iframeWindow.reset);
    if(iframeWindow.reset) {
      iframeWindow?.reset();
    }
    const {showType, introShow} = await getPreCheck();
    handleRouter(showType, introShow);
    setIntro(true);
    setLightOn(true);
  }

  const setActivityInfo = useSetRecoilState(activityInfoState);
  const setSingleAward = useSetRecoilState(singleAwardState);
  const setMultiAward = useSetRecoilState(multiAwardState);
  const setAlreadyAwardInfo = useSetRecoilState(alreadyAwardState);
  const replay = useRecoilValue(replayStatus);
  const [drawAwardCount, setDrawAwardCount] = useRecoilState(drawAwardCountState);
  const [lightOn, setLightOn] = useState(true);
  const [activityEnd, setActivityEnd] = useState(false);

  /**
   * @description: 依据code跳转
   * @param {*} code
   * @return {*}
   */
  const navigateByCode = (code) => {
    navigate(NAVIGATE_MAP[code]);
  }
  // 集度侧提供sdk 咖菲侧调用
  const login = () => {
    // 跳转小程序登录页面 
    // https://yinhe-t.cocafe.co/
    // 
    const { origin, pathname } = window.location;
    const url = `${origin}${pathname}`
    const scene = searchParams.get("scene");
    let params;
    params = scene ? { redirect: true, scene: scene } : { redirect: true} ;
    console.log('params===>', params);
    // 触发登录就会清除本地存储
    clearAllStorage();
    Login.login({
      redirectUrl: url,
      params: params
    })
    setStorage("login", true);
    setStorage("redirect", true);
  };
  // 判断是都展示规则弹框
  const intorShow = (showType) => {
    const introShow = Boolean(showType);
    if (!introShow) {
      getContext("0");
      return true;
    }
    return !introShow;
  };

  /**
   * @description: 获取不同的规则；
   * @param {*} type 0: intro展示 1: 规则展示
   * @return {*}
   */
  const getContext = async (type = "0") => {
    const res = await getRuleByType({ ruleType: type });
    if (res && res?.data) {
      const { content, title } = res.data;
      setRuleTitle(title);
      let str = generateHtml(content);
      console.log('str===>', str);
      str = str + '<br> <br> '
      type === "0" ? setMainText(str) : setRuleText(str);
    }
  };

  // 展示规则页面
  const checkRule = async () => {
    await getContext("1");
    setVisible(true);
  };
  // playGame
  const playGame = () => {
    setIntro(false);
    // setMysteryOpen(true);
    startGame();
    setTips(true);
      setLightOn(false);
  };
  // 判断是否需要登录
  const isRouterLogin = (token) => {
    const realLogin = searchParams.get("realLogin");
    console.log('token====>', token);
    console.log('realLogin====>', realLogin);
    return Boolean(token) && realLogin === 'true';
  }
  /**
   * @description: isLogin true: 已经登录 false: 需要登录：
   * @return {*}
   */  
  const isLogin = () => Boolean(getStorage("Authorization"));
  // 
  const check = () => {
    const check = getStorage('check');
    if(check.code === 10047) {
     const {origin, pathname}  = window.location;
      const url = `${origin}${pathname}`;
      centerToast('token 失效, 请重新登录！');
      setTimeout(() => {
        window.location.replace(url);
      }, 1000)
    } 
    if(!check) {
      centerToast('服务繁忙, 请刷新重试！');
    }
  }
  // 点击按钮逻辑
  const introButtonOnclick = () => {
    if (activityEnd) {
      centerToast('活动已结束');
      return false;
    }
    if (loading) {
      return
    } else if (!isLogin()) {
      login();
      // 只有出现再玩一次的场景直接进行玩游戏
    } else if (replay || code === 0) {
        check();
        playGame();
    } else {
      // setMoonVisible(false);
      check();
      navigateByCode(code);
    }
    setStorage('moon', true);
    
  
  };
  window.moonOnclick = () => {
    setMysteryOpen(true);
    setTips(false);
  };
  window.moonLoad = () => {
    setLoading(false);
  };
  //
  const firstRenderRef = useRef(true);
  const setValidStr = (key, val) => {
    if(Boolean(val)) {
      setStorage(key, val);
    } else {
      // 路由没有，查看本地是否存在。如果存在删除本地；
      if(getStorage(key)) {
        clearStorage(key);
      }
    }
  } 
  /**
   * @description: 处理埋点的路由
   * @return {*}
   * activity_id=xxx&event_source_jidu=xxx
   */  
  const handleTrack = () => {
    const event_source_jidu = searchParams.get("event_source_jidu");
    const activity_id = searchParams.get("activity_id");
    setValidStr('esj', event_source_jidu);
    setValidStr('activity_id', activity_id);
    //

  }
  // 处理token 
  const handleToken = () => {
    const token = searchParams.get("token");
    if(isRouterLogin(token)) {
      setStorage("Authorization", token);
    }
  };
  // 处理userId
  const handleUserId = () => {
    const userId = searchParams.get('userId');
    setValidStr("userId", userId);
  }
  // 处理scene场景
  const handleScene = async () => {
    //
    const scene = searchParams.get("scene");
    if (scene) {
      const params = { scene: scene };
      const msg = await getInfoByScene(params);
      setActivityInfo(msg.data);
    }
  };
  // 获取前置校验接口
  const getPreCheck = async () => {
    const data = await preCheck();
    console.log("getPreCheck===data==>", data);
    if (data&&data.code&&data.code===400013) {
      setActivityEnd(true)
    }
    setStorage('check', JSON.stringify(data));
    //
    const { showType, introShow, multiSelectAward, singlePrepareAward, alreadyAward, drawAwardCount } =
      data?.data;
    // 全局存储抽奖所用的数据
    setSingleAward(singlePrepareAward);
    setMultiAward(multiSelectAward);
    setAlreadyAwardInfo(alreadyAward);
    setDrawAwardCount(drawAwardCount);
    return {showType, introShow};
  };
  // 处理跳转和按钮展示
  const handleRouter = (showType, introShow='开始游戏') => {
    setCode(showType);
    const redirect = searchParams.get("redirect");
    setButtonText(introShow)
    if ( redirect && getStorage('redirect')) {
      if (showType) {
        // setMoonVisible(false);
        navigateByCode(showType);
        setStorage("redirect", false);
        setStorage('moon', true);
      }
    } else {
      // console.log(showType);
      setButtonText(introShow)
    }
  };
  // 
  const init = async () => {
    /* if(!isMoonVisible()) {
      setMoonVisible();
      if(getStorage('moon')) {
        setLoading(false);
        setStorage('moon', false);
      }
    }  */
    setIntro(true);
  }
  const introRef = useRef();
  
  /**
   * @description: 处理登录逻辑
   * @return {*}
   */
  useEffect(() => {
    const fetchData = async () => {
      handleTrack();
      handleToken();
      handleUserId();
      if (isLogin()) {
        handleScene();
        const {showType, introShow} = await getPreCheck();
        handleRouter(showType, introShow);
        // 处理是否展示规则介绍弹框；
        setRuleIntro(intorShow(showType));
      } else if (replay) {
        setButtonText("开始游戏");
      } else {
        setButtonText(Message_Map[0]);
      }
    };
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      fetchData();
      init();
      return;
    }
  }, []);

  return (
    <div className={`${styles["page"]}`}>
      {intro && (
        <div className={`flex-col`}>
          <div className={`flex-row justify-between ${styles["group_1"]}`}>
            <img
              src={require("./assets/761a2f75498fffacb5fbbc546894ccbb.png")}
              className={`${styles["image"]} slideInDown`}
            />
            <div
              onClick={() => checkRule()}
              className={`flex-col items-center ${styles["text-wrapper"]} slideInDown`}
            >
              <span className={`${styles["text"]} ${styles["text_1"]}`}>
                规则
              </span>
            </div>
          </div>
          <div className={`slideInDown ${styles["image_1"]}`}></div>
          <div className={`flex-row slideInDown ${styles["group_2"]}`} ref={introRef} >
            <span className={`${styles["text_2"]}`}>探月寻兔</span>
          </div>
          <div className={`${styles["text"]} ${styles["text_4"]}`}>
            <div className="slideInDown">开舱门 赢权益 看发射</div>
            {loading&&
            <div className={`${styles["loading"]}`}>
              <img src={require("./assets/loading.gif")} />
            </div>}
          </div>
          <div className={`${styles["group_3"]}`}>
            <div className={`${styles["group_4"]}`}>
              {!loading&&<Button
                content={buttonText}
                isConfirm={true}
                clickEvent={throttle(introButtonOnclick)}
                style={{
                  width: "calc(315 / 375 * 100vw)",
                  height: "calc(48 / 375 * 100vw)",
                  fontSize: "calc(14 / 375 * 100vw)",
                }}
              />}
            </div>
          </div>
        </div>
      )}
      {intro && (
        <div className={`flex-col slideInUp ${styles["group_5"]}`}>
          <span className={`${styles["text"]} ${styles["text_6"]}`}>·</span>
          <div className={`flex-row justify-between`}>
            <div
              className={`flex-col items-center ${styles["text-wrapper_1"]}`}
            >
              <span className={`${styles["text"]} ${styles["text_7"]}`}>
                LE
              </span>
            </div>
            <div className={`flex-col ${styles["group_7"]}`}>
              <span className={`${styles["text"]} ${styles["text_8"]}`}>
                robo-01
              </span>
              <span className={`${styles["text"]} ${styles["text_9"]}`}>
                lunar edition
              </span>
            </div>
          </div>
        </div>
      )}
      {tips && (
        <div className={styles["tips-line"]}>
          <div>点击光点探索</div>
          <img className={styles["line"]} src={require("./assets/tips-line.png")}/>
        </div>
      )}
      {tips && (
        <div className={styles["tips-content"]}>
          <div className={styles["tips-toast"]}>
            TIPS：双指放大
          </div>
          {drawAwardCount > 0 && <div className={styles["tips-number"]}>剩余游戏次数: {drawAwardCount}</div>}
        </div>
      )}
      <iframe
        id="moon"
        title="moon"
        className={`flex-row ${styles["game"]}`}
        src={"/moon.html"}
        frameBorder="0"
        allowFullScreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        style={{width: "100%", height: "100%", border: "none"}}
      />
      {lightOn&&
      <div className={styles["bottom-light"]}/>}
      {/* <div  className={`flex-row ${styles["game"]} ${styles[moonShow]}`}>
        <Moon hide={false}></Moon>
      </div> */}
      <MysteryBoxModal open={mysteryOpen} setOpen={setMysteryOpen} reset={reset}/>
      <IntroModal
        setRuleIntro={setRuleIntro}
        ruleIntro={ruleIntro}
        title={ruleTitle}
        mainText={mainText}
      ></IntroModal>
      <RuleModal
        setVisible={setVisible}
        visible={visible}
        contentText={ruleText}
      ></RuleModal>
      <link rel="preload" as="image" href={invitePageBg} />
      <link rel="preload" as="image" href={redeemSuccessBg} />
      <link rel="preload" as="image" href={bgShort} />
      <link rel="preload" as="image" href={bottomLight} />
    </div>
  );
}
