import { centerToast } from "../components/Toast/Toast";
import Login from "./login";
import { clearAllStorage } from "./functionUtils";
import fetch_timeout from './fetchTimeout'

/*
 * @Descripttion: 
 * @version: 
 * @Author: caixin
 * @Date: 2022-08-31 19:07:03
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-09-21 18:27:14
 */
class HTTP {
  constructor() {
    this.baseUrl = process.env.REACT_APP_SERVER_ADDRESS
  }

  params(obj) {
    let result = '';
    let item;
    for (item in obj) {
      if ((obj[item] && String(obj[item]))||obj[item]===0) {
        result += `&${item}=${obj[item]}`;
      }
    }
    if (result) {
      result = '?' + result.slice(1);
    }
    return result;
  }

  async request(method, url, data = {}) {
    const token = localStorage.getItem('Authorization')
    let headers = new Headers();
    headers.append("Authorization", token);
    headers.append("content-type", 'application/json');

    try {
      if (method === 'POST') {
        const requestUrl = `${this.baseUrl}/${url}`
        const response = await fetch_timeout(fetch(
          requestUrl,
          {
            method,
            headers,
            body: JSON.stringify(data)
          }
        ))
        if (response&&response.failCode&&response.failCode===504) {
          centerToast('请求超时！');
          return;
        }
        if (!response||response.status !== 200) {
          // handle error
          centerToast(response.status||response);
          return;
        }
        const result = await response.json();
        if (result.code === 0) {
          return result
        } else if (result.code === 10047) {
          const {origin}  = window.location;
          const url = `${origin}/activity-nft/`;
          const params = { redirect: true };
          Login.login({
            redirectUrl: url,
            params: params
          })
          clearAllStorage();
          return result;
        } else {
          // handle other situations
          centerToast(result.msg);
        }
      } else if (method === 'GET') {
        const requestUrl = `${this.baseUrl}/${url}${this.params(data)}`
        const response = await fetch_timeout(fetch(
          requestUrl,
          {
            method,
            headers
          }
        ))
        if (response&&response.failCode&&response.failCode===504) {
          centerToast('请求超时！');
          return;
        }
        if (!response||response.status !== 200) {
          // handle error
          centerToast(response.status);
          return;
        }
        const result = await response.json();
        if (result.code === 0) {
          return result
        } else if (result.code === 10047) {
          const {origin, pathname}  = window.location;
          const url = `${origin}${pathname}`;
          const params = { redirect: true };
          Login.login({
            redirectUrl: url,
            params: params
          })
          clearAllStorage();
          return result;
        } else if (result.code === 400013) {
          centerToast(result.msg);
          return result;
        } else {
          // handle other situations
          centerToast(result.msg);
        }
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  }
}

export default new HTTP()