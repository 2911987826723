import './MusicIcon.scss';
import { useState, useEffect } from "react";
import Music from '../../assets/common/audio.mp3'
import { useNavigate } from 'react-router-dom';

export default function MusicIcon () {
  const navigate = useNavigate();
  const [muted, setMuted] = useState(false)
  const [top, setTop] = useState('23vw')

  useEffect(() => {
    if (window.location.pathname==='/activity-nft/') {
      if (top!=='23vw') {
        setTop('23vw')
      }
    } else {
      setTop('6.4vw')
    }
  },[navigate])

  window.onload= () => {
    const music = document.getElementById('jd-music');
    music.play()
  }
  document.addEventListener(
    'WeixinJSBridgeReady',
    function () {
      const music = document.getElementById('jd-music');
      music.play()
    },
    false
  );

  const muteMusic = () => {
    setMuted(true)
    const music = document.getElementById('jd-music');
    music.pause()
  }

  const playMusic = () => {
    setMuted(false)
    const music = document.getElementById('jd-music');
    music.play()
  }

  return (
    <div className={`music-icon ${muted ?' muted-icon':''} ${top==='6.4vw'?'':'slideInDown'}`} onClick={muted?playMusic:muteMusic} style={{top}}>
      <audio
        id='jd-music'
        preload="true"
        autoPlay
        loop
        src={Music}>
      </audio>
    </div>
  )
}