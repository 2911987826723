import './InviteeInfoCard.scss';

/*
* @Description: 邀请助力页-成功邀请的好友的信息展示组件
* @inviteeInfo: 成功邀请的好友的信息传参
* @version: 
* @Author: hongtao
* @Date:
* @LastEditors: hongtao
* @LastEditTime:
*/
export default function InviteeInfoCard({inviteeInfo}) {
  return (
    <div className="invitee-info-container">
      <img
        src={inviteeInfo.inviteePoster}
        className="invitee-avatar"
        alt=""
      />
      <div className="invitee-name">{inviteeInfo.inviteeName}</div>
      <div className="invitee-phone-num">{inviteeInfo.mobileSuffix}</div>
    </div>
  )
}